<template>
<div>
<v-app>
  <Datatable />
</v-app>
  </div>
</template>

<script>
  import Datatable from '../components/Datatable'

  export default {
    name: 'Membertable',

    components: {
      Datatable,
    },
  }
</script>
